<template>
  <base-dialog
    :show="isEmail"
    @close="closeAllEmail"
    :title="`${t.year} ${t.manufacturer} ${t.model}`"
    :width="true"
  >
    <form @submit.prevent="" class="email-form">
      <div @click="closeAllEmail" class="close-btn">&times;</div>
      <div class="d-sm-flex gap-3">
        <div class="left">
          <div class="input-wrap mb-2">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !firstName && isEmpty }"
              for="firstName"
              >First Name</label
            >
            <input
              v-model="firstName"
              class="form-control border"
              type="text"
              id="firstName"
              placeholder="Enter your first name"
            />
          </div>
          <div class="input-wrap mb-2">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !lastName && isEmpty }"
              for="lastName"
              >Last Name</label
            >
            <input
              v-model="lastName"
              class="form-control border"
              type="text"
              id="lastName"
              placeholder="Enter your last name"
            />
          </div>
          <div class="input-wrap mb-2">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !email && isEmpty }"
              for="email"
              >Email</label
            >
            <input
              v-model="email"
              class="form-control border"
              type="email"
              id="email"
              placeholder="Enter your email address"
            />
          </div>
          <div class="input-wrap">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !recEmail && isEmpty }"
              for="recEmail"
              >Recipient's Email</label
            >
            <input
              v-model="recEmail"
              class="form-control border"
              type="email"
              id="recEmail"
              placeholder="Enter recipient's email address"
            />
          </div>
        </div>
        <div class="right">
          <div class="input-wrap">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !message && isEmpty }"
              for="message"
              >Message</label
            >
            <textarea
              v-model="message"
              class="form-control border h-100"
              id="message"
              maxlength="500"
              placeholder="Enter your inquiry (Up to 500 characters)"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="d-flex gap-3 mt-3">
        <div class="wrap">
          <input v-model="isConfirm" type="checkbox" id="rule" />
          <label
            class="confirm-label"
            for="rule"
            :class="{ 'text-danger': !isConfirm && isEmpty }"
            >Click here to acknowledge you understand our Privacy Policy, which
            explains how the Sandhills Group Companies use and collect personal
            information when you register with us or place an order for any of
            our Brands. We will not share your information for marketing
            purposes with companies outside our group companies and you may
            withdraw your consent at any time.</label
          >
        </div>
        <div class="button-wrap d-flex flex-column align-items-center gap-2">
          <recaptcha-button></recaptcha-button>
          <button
            @click="submitEmail"
            class="d-flex justify-content-center gap-2 align-items-center align-self-end"
          >
            Submit
            <base-loader v-if="loading"></base-loader>
          </button>
        </div>
      </div>
    </form>
  </base-dialog>
  <!-- email 2 bottom -->
  <base-dialog
    :show="isEmail2"
    @close="closeAllEmail"
    :title="`${t.year} ${t.manufacturer} ${t.model}`"
    :width="true"
  >
    <form @submit.prevent="" class="second-form">
      <div @click="closeAllEmail" class="close-btn">&times;</div>
      <div class="d-sm-flex gap-3">
        <div class="left">
          <div class="input-wrap mb-2">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !firstName && isEmpty }"
              for="firstName"
              >First Name</label
            >
            <input
              v-model="firstName"
              class="form-control"
              type="text"
              id="firstName"
              placeholder="Enter your first name"
            />
          </div>
          <div class="input-wrap mb-2">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !lastName && isEmpty }"
              for="lastName"
              >Last Name</label
            >
            <input
              v-model="lastName"
              class="form-control"
              type="text"
              id="lastName"
              placeholder="Enter your last name"
            />
          </div>
          <div class="input-wrap mb-2">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !email && isEmpty }"
              for="email"
              >Email</label
            >
            <input
              v-model="email"
              class="form-control"
              type="email"
              id="email"
              placeholder="Enter your email address"
            />
          </div>
          <div class="input-wrap">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !phone && isEmpty }"
              for="phone"
              >Phone</label
            >
            <div class="input-group">
              <base-dropdown
                :options="allCountry"
                @input="getCountry"
              ></base-dropdown>
              <input
                v-model="phone"
                id="phone"
                class="form-control"
                type="text"
              />
            </div>
          </div>
          <div class="input-wrap mb-2">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !postalCode && isEmpty }"
              for="postalCode"
              >Postal Code</label
            >
            <input
              v-model="postalCode"
              class="form-control"
              type="text"
              id="postalCode"
              placeholder="Enter your postal code"
            />
            <small>Postal code min 5 max 6 characters</small>
          </div>
        </div>
        <div class="right">
          <div class="input-wrap">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !message && isEmpty }"
              for="message"
              >Message</label
            >
            <textarea
              v-model="message"
              class="form-control"
              id="message"
              maxlength="500"
              placeholder="Enter your inquiry (Up to 500 characters)"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="d-flex gap-3 mt-3">
        <div class="wrap">
          <input v-model="isConfirm" type="checkbox" id="rule" />
          <label
            class="confirm-label"
            for="rule"
            :class="{ 'text-danger': !isConfirm && isEmpty }"
            >Click here to acknowledge you understand our Privacy Policy, which
            explains how the Sandhills Group Companies use and collect personal
            information when you register with us or place an order for any of
            our Brands. We will not share your information for marketing
            purposes with companies outside our group companies and you may
            withdraw your consent at any time.</label
          >
        </div>
        <div class="button-wrap d-flex flex-column align-items-center gap-2">
          <recaptcha-button></recaptcha-button>
          <button
            @click="sendEmail2"
            class="d-flex justify-content-center gap-2 align-items-center align-self-end"
          >
            Submit
            <base-loader v-if="loading"></base-loader>
          </button>
        </div>
      </div>
    </form>
  </base-dialog>
  <!-- video -->
  <base-dialog
    :show="isVideoCall"
    @close="closeAllEmail"
    :title="`${t.year} ${t.manufacturer} ${t.model}`"
  >
    <form @submit.prevent="" class="video-form">
      <div @click="closeAllEmail" class="close-btn">&times;</div>
      <div class="d-sm-flex gap-3">
        <div class="left">
          <div class="input-wrap mb-2">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !firstName && isEmpty }"
              for="firstName"
              >First Name</label
            >
            <input
              v-model="firstName"
              class="form-control"
              type="text"
              id="firstName"
              placeholder="Enter your first name"
            />
          </div>
          <div class="input-wrap mb-2">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !lastName && isEmpty }"
              for="lastName"
              >Last Name</label
            >
            <input
              v-model="lastName"
              class="form-control"
              type="text"
              id="lastName"
              placeholder="Enter your last name"
            />
          </div>
          <div class="input-wrap mb-2">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !email && isEmpty }"
              for="email"
              >Email</label
            >
            <input
              v-model="email"
              class="form-control"
              type="email"
              id="email"
              placeholder="Enter your email address"
            />
          </div>
          <div class="input-wrap">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !phone && isEmpty }"
              for="phone"
              >Phone</label
            >
            <div class="input-group">
              <base-dropdown
                :options="allCountry"
                @input="getCountry"
              ></base-dropdown>
              <input
                v-model="phone"
                class="form-control"
                id="phone"
                type="text"
              />
            </div>
          </div>
          <div class="input-wrap mb-2">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !postalCode && isEmpty }"
              for="postalCode"
              >Postal Code</label
            >
            <input
              v-model="postalCode"
              class="form-control"
              type="text"
              id="postalCode"
              placeholder="Enter your postal code"
            />
            <small>Postal code min 5 max 6 characters</small>
          </div>
        </div>
        <div class="right">
          <div class="input-wrap mb-2">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !service && isEmpty }"
              for="lastName"
              >Video Chat Service</label
            >
            <select
              v-model="service"
              class="form-select"
              aria-label="Default select example"
            >
              <option selected value="">Service</option>
              <option value="apple">Apple</option>
              <option value="android">Android</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div class="input-wrap mb-2">
            <label
              class="fw-bold"
              :class="{ 'text-danger': (day?.length == 0 || !time) && isEmpty }"
              for="lastName"
            >
              When would you like to video chat?</label
            >
            <div class="d-flex gap-2">
              <select
                v-model="time"
                class="form-select"
                aria-label="Default select example"
              >
                <option selected value="">Time</option>
                <option value="1">8am-12pm</option>
                <option value="2">12pm-3pm</option>
                <option value="3">3pm-6pm</option>
              </select>
              <select
                v-model="day"
                class="form-select"
                aria-label="Default select example"
              >
                <option selected value="">Day</option>
                <option v-for="(day, i) in days" :key="day" :value="i">
                  {{ day }}
                </option>
              </select>
            </div>
          </div>
          <div class="input-wrap">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !message && isEmpty }"
              for="message"
              >Message</label
            >
            <textarea
              v-model="message"
              class="form-control"
              id="message"
              maxlength="500"
              :placeholder="`I’d like to schedule a video chat to talk about the ${t.year} ${t.manufacturer} ${t.model} you have listed on www.ea-trucks.com.`"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="d-flex gap-3 mt-3">
        <div class="wrap">
          <input v-model="isConfirm" type="checkbox" id="rule" />
          <label
            class="confirm-label"
            for="rule"
            :class="{ 'text-danger': !isConfirm && isEmpty }"
            >Click here to acknowledge you understand our Privacy Policy, which
            explains how the Sandhills Group Companies use and collect personal
            information when you register with us or place an order for any of
            our Brands. We will not share your information for marketing
            purposes with companies outside our group companies and you may
            withdraw your consent at any time.</label
          >
        </div>
        <div class="button-wrap d-flex flex-column align-items-center gap-2">
          <recaptcha-button></recaptcha-button>
          <button
            @click="sendVideoEmail"
            class="d-flex justify-content-center gap-2 align-items-center align-self-end"
          >
            Submit <base-loader v-if="loading"></base-loader>
          </button>
        </div>
      </div>
    </form>
  </base-dialog>
  <!-- make offer -->
  <base-dialog
    :show="isOffer"
    @close="closeAllEmail"
    :title="`${t.year} ${t.manufacturer} ${t.model}`"
  >
    <form @submit.prevent="" class="offer">
      <div @click="closeAllEmail" class="close-btn">&times;</div>
      <div class="d-sm-flex gap-3">
        <div class="left">
          <div class="input-wrap mb-2">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !firstName && isEmpty }"
              for="firstName"
              >First Name</label
            >
            <input
              v-model="firstName"
              class="form-control"
              type="text"
              id="firstName"
              placeholder="Enter your first name"
            />
          </div>
          <div class="input-wrap mb-2">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !lastName && isEmpty }"
              for="lastName"
              >Last Name</label
            >
            <input
              v-model="lastName"
              class="form-control"
              type="text"
              id="lastName"
              placeholder="Enter your last name"
            />
          </div>
          <div class="input-wrap mb-2">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !email && isEmpty }"
              for="email"
              >Email</label
            >
            <input
              v-model="email"
              class="form-control"
              type="email"
              id="email"
              placeholder="Enter your email address"
            />
          </div>
          <div class="input-wrap">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !phone && isEmpty }"
              for="phone"
              >Phone</label
            >
            <div class="input-group">
              <base-dropdown
                :options="allCountry"
                @input="getCountry"
              ></base-dropdown>
              <input
                v-model="phone"
                class="form-control"
                id="phone"
                type="tel"
              />
            </div>
          </div>
          <div class="input-wrap mb-2">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !postalCode && isEmpty }"
              for="postalCode"
              >Postal Code</label
            >
            <input
              v-model="postalCode"
              class="form-control"
              type="text"
              id="postalCode"
              placeholder="Enter your postal code"
            />
          </div>
        </div>
        <div class="right">
          <div class="d-flex gap-2 mb-2">
            <div class="input-wrap w-75">
              <label
                for="offer"
                :class="{ 'text-danger': !offer && isEmpty }"
                class="fw-bold"
                >Offer Amount</label
              >
              <input
                v-model="offer"
                class="form-control"
                type="number"
                placeholder="Enter offer amount"
                id="offer"
              />
            </div>
            <div class="input-wrap">
              <label class="fw-bold">Currency</label>
              <select
                v-model="currency"
                class="form-select"
                aria-label="Default select example"
              >
                <option v-for="curr in getCurrency" :key="curr" :value="curr">
                  {{ curr }}
                </option>
              </select>
            </div>
          </div>
          <div class="input-wrap">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !message && isEmpty }"
              for="message"
              >Message</label
            >
            <textarea
              v-model="message"
              class="form-control"
              id="message"
              maxlength="500"
              placeholder="Enter your inquiry (Up to 500 characters)"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="d-flex gap-3 mt-3">
        <div class="wrap">
          <input v-model="isConfirm" type="checkbox" id="rule" />
          <label
            class="confirm-label"
            for="rule"
            :class="{ 'text-danger': !isConfirm && isEmpty }"
            >Click here to acknowledge you understand our Privacy Policy, which
            explains how the Sandhills Group Companies use and collect personal
            information when you register with us or place an order for any of
            our Brands. We will not share your information for marketing
            purposes with companies outside our group companies and you may
            withdraw your consent at any time.</label
          >
        </div>
        <div class="button-wrap d-flex align-items-center flex-column gap-2">
          <recaptcha-button></recaptcha-button>
          <button
            @click="submitOffer"
            class="d-flex justify-content-center gap-2 align-items-center align-self-end"
          >
            Submit <base-loader v-if="loading"></base-loader>
          </button>
        </div>
      </div>
    </form>
  </base-dialog>
  <!-- calculator -->
  <base-dialog :show="isCalculator" @close="closeCalculator">
    <div @click="isCalculator = false" class="calc close-btn">&times;</div>
    <div class="calculator-wrap d-flex justify-content-between">
      <div class="w-50">
        <div class="calc-inp">
          <label class="fw-bold">Payment Frequency</label>
          <select class="form-select">
            <option value="1">Monthly</option>
            <option value="1">Quarterly</option>
            <option value="1">Bi-Annually</option>
            <option value="1">Annually</option>
          </select>
        </div>
        <div class="calc-inp">
          <label class="fw-bold">Number of Payments</label>
          <input class="form-control" type="number" />
        </div>
        <div class="calc-inp">
          <label class="fw-bold">Interest %</label>
          <input class="form-control" type="number" />
        </div>
        <div class="d-flex gap-2">
          <div class="calc-inp">
            <label class="fw-bold">Loan Amount $</label>
            <input class="form-control" type="number" />
          </div>
          <div class="calc-inp">
            <label class="fw-bold">Currency</label>
            <input class="form-control" type="text" value="USD" disabled />
          </div>
        </div>
        <div class="d-flex gap-2 mt-3 title">
          <button class="w-50">Calculate</button>
          <button class="w-50">Reset</button>
        </div>
      </div>
      <div class="w-45 border p-1">
        <div class="d-flex justify-content-between">
          <p class="fw-bold">Payments:</p>
          <p>$3,433.02</p>
        </div>
        <div class="d-flex justify-content-between">
          <p class="fw-bold">Amount Financed:</p>
          <p>$49,950.00</p>
        </div>
        <div class="d-flex justify-content-between">
          <p class="fw-bold">Total Amount:</p>
          <p>$51,631.15</p>
        </div>
        <div class="d-flex justify-content-between">
          <p class="fw-bold">Finance Charge:</p>
          <p>$1,681.15</p>
        </div>
      </div>
    </div>
  </base-dialog>
  <section v-if="t.length">
    <div class="home-sub-title text-center">
      <p>5501 Adams ST Unit C Matteson, IL 60443</p>
    </div>
    <div class="home-welcome text-white text-center">
      <h2>{{ t.year }} {{ t.manufacturer }} {{ t.model }}</h2>
    </div>
  </section>
  <section class="my-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 p-0 mb-3">
          <div class="title d-flex gap-2">
            <div class="me-auto">
              <router-link to="/trucks">
                <fa class="fa" :icon="['fas', 'angle-left']" /> Search Results
              </router-link>
            </div>
            <button @click="printPdf">
              <fa class="fa text-light" :icon="['fas', 'print']" /> Print
            </button>
            <button @click="isEmail = true">
              <fa class="fa text-light" :icon="['fas', 'envelope']" /> Email
            </button>
          </div>
        </div>
      </div>
      <div class="row justify-content-center gap-2">
        <div class="col-md-4 p-0 mb-3">
          <div v-if="t.media?.length">
            <base-slider :allMedia="t.media"></base-slider>
          </div>
          <div v-else>
            <img class="border" src="../../assets/no-image.png" alt="" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="box">
            <h5 class="mb-1">
              {{ t.year }} {{ t.manufacturer }} {{ t.model }}
            </h5>
            <span>{{ t.category }}</span>
            <div class="my-1 d-flex align-items-center gap-2">
              <select v-model="secondCurrency" @change="convert(t.price)">
                <option v-for="curr in getCurrency" :key="curr" :value="curr">
                  {{ curr }}
                </option>
              </select>
              <p class="fs-5">
                <span v-if="secondCurrency === 'USD'" class="fs-5">$</span>
                {{ amount ? addSpace(amount) : addSpace(t.price) }}
              </p>
            </div>
            <div class="d-flex gap-4 mt-2">
              <div>
                <button @click="isOffer = true">
                  <fa class="fa" :icon="['fas', 'tag']" /> Make An Offer
                </button>
              </div>
            </div>
            <div v-if="t?.media?.length" class="icons d-flex gap-3 mt-3">
              <ShareNetwork
                network="facebook"
                :url="baseUrl + t.media[0].image"
                title="Trucks"
                hashtags="trucks"
              >
                <button>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-brand-facebook"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"
                    ></path>
                  </svg>
                </button>
              </ShareNetwork>
              <ShareNetwork
                network="twitter"
                :url="baseUrl + t.media[0].image"
                title="Trucks"
                hashtags="trucks"
              >
                <button>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-brand-twitter"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      d="M22 4.01c-1 .49 -1.98 .689 -3 .99c-1.121 -1.265 -2.783 -1.335 -4.38 -.737s-2.643 2.06 -2.62 3.737v1c-3.245 .083 -6.135 -1.395 -8 -4c0 0 -4.182 7.433 4 11c-1.872 1.247 -3.739 2.088 -6 2c3.308 1.803 6.913 2.423 10.034 1.517c3.58 -1.04 6.522 -3.723 7.651 -7.742a13.84 13.84 0 0 0 .497 -3.753c-.002 -.249 1.51 -2.772 1.818 -4.013z"
                    ></path>
                  </svg>
                </button>
              </ShareNetwork>
              <ShareNetwork
                network="LinkedIn"
                :url="baseUrl + t.media[0].image"
                title="Trucks"
                hashtags="trucks"
              >
                <button>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-brand-linkedin"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <rect x="4" y="4" width="16" height="16" rx="2"></rect>
                    <line x1="8" y1="11" x2="8" y2="16"></line>
                    <line x1="8" y1="8" x2="8" y2="8.01"></line>
                    <line x1="12" y1="16" x2="12" y2="11"></line>
                    <path d="M16 16v-3a2 2 0 0 0 -4 0"></path>
                  </svg>
                </button>
              </ShareNetwork>
              <button @click="isShareOpen = true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-plus"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <line x1="12" y1="5" x2="12" y2="19"></line>
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
              </button>
            </div>
          </div>
          <h5 class="text-danger my-2">SELLER INFORMATION</h5>
          <div
            class="d-flex justify-content-between align-items-center send-email"
          >
            <div>
              <h6 class="fw-bold">SELLER INFORMATION E & A Truck Sales</h6>
              <p>5501 Adams, IL 60443</p>
            </div>
            <button @click="isEmail2 = true">
              <fa class="fa" :icon="['fas', 'envelope']" /> Send Email
            </button>
          </div>
          <div
            class="d-flex justify-content-between align-items-center send-email"
          >
            <div>
              <p><b>Phone:</b> (312) 678-0974</p>
              <p><b>Contact:</b> Sales</p>
            </div>
            <!-- <button @click="isCalculator = true">
              <fa class="fa" :icon="['fas', 'calculator']" /> Financial
              Calculator
            </button> -->
          </div>

          <p @click="isVideoCall = true" class="text-decoration-underline">
            Video Chat With This Dealer
          </p>
          <h6 class="fw-bold">Machine Location:</h6>
          <p>
            <fa class="fa" :icon="['fas', 'location-dot']" /> 5501 Adams ST Unit
            C Matteson, IL 60443
          </p>
        </div>
      </div>
      <div class="row justify-content-center gap-2 general">
        <div class="col-md-8 p-0 mb-3">
          <h5 class="mb-2">General</h5>
          <div class="general-table">
            <div class="d-flex gap-2">
              <div class="first p-1">
                <h6>Year</h6>
              </div>
              <div class="second p-1">
                <h6>{{ t.year }}</h6>
              </div>
            </div>
            <div class="d-flex gap-2">
              <div class="first p-1">
                <h6>Manufacturer</h6>
              </div>
              <div class="second p-1">
                <h6>{{ t.manufacturer }}</h6>
              </div>
            </div>
            <div class="d-flex gap-2">
              <div class="first p-1">
                <h6>Model</h6>
              </div>
              <div class="second p-1">
                <h6>{{ t.model }}</h6>
              </div>
            </div>
            <div class="d-flex gap-2">
              <div class="first p-1">
                <h6>VIN</h6>
              </div>
              <div class="second p-1">
                <h6>{{ t.vin }}</h6>
              </div>
            </div>
            <div class="d-flex gap-2">
              <div class="first p-1">
                <h6>Condition</h6>
              </div>
              <div class="second p-1">
                <h6>{{ t.condition }}</h6>
              </div>
            </div>
            <div class="d-flex gap-2">
              <div class="first p-1">
                <h6>Stock Number</h6>
              </div>
              <div class="second p-1">
                <h6>{{ t.stock_number }}</h6>
              </div>
            </div>
            <div class="d-flex gap-2">
              <div class="first p-1">
                <h6>Mileage</h6>
              </div>
              <div class="second p-1">
                <h6 v-if="t.mileage">{{ addSpace(t.mileage) }} mi</h6>
              </div>
            </div>
            <div class="d-flex gap-2">
              <div class="first p-1">
                <h6>Odometer</h6>
              </div>
              <div class="second p-1">
                <h6>{{ t.odometer }}</h6>
              </div>
            </div>
            <div class="d-flex gap-2">
              <div class="first p-1">
                <h6>State DOT</h6>
              </div>
              <div class="second p-1">
                <h6>{{ t.state_dot }}</h6>
              </div>
            </div>
            <div class="d-flex gap-2">
              <div class="first p-1">
                <h6>Description</h6>
              </div>
              <div class="second p-1">
                <p v-for="string in getDesc(t.description)" :key="string">
                  {{ string }}
                </p>
              </div>
            </div>
          </div>
          <!--  -->
          <template v-if="t.truck_engine">
            <h5 class="mt-3 mb-2">Engine</h5>
            <div class="general-table">
              <div
                v-for="(value, key, i) of t.truck_engine"
                :key="i"
                class="d-flex gap-2"
              >
                <div class="first p-1">
                  <h6 class="key">{{ capitalizeProp(key) }}</h6>
                </div>
                <div class="second p-1">
                  <h6>
                    {{ value }} <span v-if="key == 'horsepower'">HP</span>
                  </h6>
                </div>
              </div>
            </div>
          </template>
          <!--  -->
          <template v-if="t.truck_powertrain">
            <h5 class="mt-3 mb-2">Powertrain</h5>
            <div class="general-table">
              <div
                v-for="(value, key, i) of t.truck_powertrain"
                :key="i"
                class="d-flex gap-2"
              >
                <div class="first p-1">
                  <h6 class="key">{{ capitalizeProp(key) }}</h6>
                </div>
                <div class="second p-1">
                  <h6>{{ value }}</h6>
                </div>
              </div>
            </div>
          </template>
          <!--  -->
          <template v-if="t.truck_chassis">
            <h5 class="mt-3 mb-2">Chassis</h5>
            <div class="general-table">
              <div
                v-for="(value, key, i) of t.truck_chassis"
                :key="i"
                class="d-flex gap-2"
              >
                <div class="first p-1">
                  <h6 class="key">
                    {{
                      key == "gvwr"
                        ? "Gross Vehicle Weight Rating"
                        : capitalizeProp(key)
                    }}
                  </h6>
                </div>
                <div class="second p-1">
                  <h6>{{ value }}</h6>
                </div>
              </div>
            </div>
          </template>
          <!--  -->
          <template v-if="t.truck_interior">
            <h5 class="mt-3 mb-2">Interior</h5>
            <div class="general-table">
              <div
                v-for="(value, key, i) of t.truck_interior"
                :key="i"
                class="d-flex gap-2"
              >
                <div class="first p-1">
                  <h6 class="key">{{ capitalizeProp(key) }}</h6>
                </div>
                <div class="second p-1">
                  <h6>{{ value }}</h6>
                </div>
              </div>
            </div>
          </template>
          <!--  -->
          <template v-if="t.truck_exterior">
            <h5 class="mt-3 mb-2">Exterior</h5>
            <div class="general-table">
              <div
                v-for="(value, key, i) of t.truck_exterior"
                :key="i"
                class="d-flex gap-2"
              >
                <div class="first p-1">
                  <h6 class="key">{{ capitalizeProp(key) }}</h6>
                </div>
                <div class="second p-1">
                  <h6>{{ value }}</h6>
                </div>
              </div>
            </div>
          </template>
          <!--  -->
          <template v-if="t.truck_attachments">
            <h5 class="mt-3 mb-2">Attachments</h5>
            <div class="general-table">
              <div
                v-for="(value, key, i) of t.truck_attachments"
                :key="i"
                class="d-flex gap-2"
              >
                <div class="first p-1">
                  <h6 class="key">{{ capitalizeProp(key) }}</h6>
                </div>
                <div class="second p-1">
                  <h6>{{ value }}</h6>
                </div>
              </div>
            </div>
          </template>
          <!--  -->
          <template v-if="t.truck_category_specific">
            <h5 class="mt-3 mb-2">Category Specific</h5>
            <div class="general-table">
              <div
                v-for="(value, key, i) of t.truck_category_specific"
                :key="i"
                class="d-flex gap-2"
              >
                <div class="first p-1">
                  <h6 class="key">{{ capitalizeProp(key) }}</h6>
                </div>
                <div class="second p-1">
                  <h6>{{ value }}</h6>
                </div>
              </div>
            </div>
          </template>
          <!--  -->
          <template v-if="t.truck_capacities">
            <h5 class="mt-3 mb-2">Capacities</h5>
            <div class="general-table">
              <div
                v-for="(value, key, i) of t.truck_capacities"
                :key="i"
                class="d-flex gap-2"
              >
                <div class="first p-1">
                  <h6 class="key">{{ capitalizeProp(key) }}</h6>
                </div>
                <div class="second p-1">
                  <h6>{{ value }}</h6>
                </div>
              </div>
            </div>
          </template>
          <!--  -->
          <template v-if="t.truck_dimensions">
            <h5 class="mt-3 mb-2">Weights & Dimensions</h5>
            <div class="general-table">
              <div
                v-for="(value, key, i) of t.truck_dimensions"
                :key="i"
                class="d-flex gap-2"
              >
                <div class="first p-1">
                  <h6 class="key">{{ capitalizeProp(key) }}</h6>
                </div>
                <div class="second p-1">
                  <h6>{{ value }} ft</h6>
                </div>
              </div>
            </div>
          </template>
          <!--  -->
        </div>
      </div>
    </div>
  </section>
  <div class="w-100 second-footer p-2 d-sm-none d-block">
    <div class="d-flex gap-2 title">
      <button @click="isEmail2 = true" class="w-50">
        <fa class="fa" :icon="['fas', 'envelope']" /> Send Email
      </button>
      <a class="w-50 text-light" href="tel:630-756-2614"
        ><button class="w-100">
          <fa class="mx-1" :icon="['fas', 'arrow-circle-right']" />Call Seller
        </button></a
      >
    </div>
  </div>
  <share-links
    v-if="isShareOpen"
    :base="baseUrl"
    :img="t.media[0].image"
    :title="`${t.year} ${t.manufacturer} ${t.model}`"
    @closeLinks="isShareOpen = false"
    @printEmit="printPdf"
  ></share-links>
  <base-footer></base-footer>
</template>

<script>
import axios from "axios";
import customAxios from "../../api";
import { defineAsyncComponent } from "vue";
const BaseDialog = defineAsyncComponent(() => import("../UI/BaseDialog.vue"));
const ShareLinks = defineAsyncComponent(() => import("../UI/ShareLinks.vue"));
const BaseLoader = defineAsyncComponent(() => import("../UI/BaseLoader.vue"));
import CountryList from "country-list-with-dial-code-and-flag";
import BaseDropdown from "../UI/BaseDropdown.vue";
import currency from "../../currency";
import BaseSlider from "../UI/BaseSlider.vue";
import { getDays, addSpace, capitalizeProp, checkEmail } from "../../utils";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  props: ["id"],
  components: {
    BaseSlider,
    BaseDialog,
    BaseDropdown,
    ShareLinks,
    BaseLoader,
  },
  data() {
    return {
      host: "api.frankfurter.app",
      amount: null,
      secondCurrency: "USD",
      firstName: "",
      lastName: "",
      email: "",
      recEmail: "",
      isConfirm: false,
      message: "",
      phone: "",
      phoneCode: "+1",
      postalCode: "",
      service: "",
      time: "",
      day: "",
      offer: "",
      currency: "USD",
      isEmpty: false,
      isEmail: false,
      isEmail2: false,
      isVideoCall: false,
      isOffer: false,
      isCalculator: false,
      isShareOpen: false,
      allCountry: CountryList,
      days: getDays(),
      baseUrl: process.env.VUE_APP_IMAGE_URL,
      t: [],
    };
  },
  computed: {
    loading() {
      return this.$store.state.isLoading;
    },
    getCurrency() {
      const keys = Object.keys(currency);
      return keys;
    },
    filteredPhone() {
      return this.phoneCode + this.phone;
    },
    startDay() {
      let time, day;
      day = new Date(new Date().getTime() + this.day * 86400000)
        .toJSON()
        .slice(0, 10);
      if (this.time == 1) time = "08:00 " + day;
      else if (this.time == 2) time = "12:00 " + day;
      else if (this.time == 3) time = "15:00 " + day;
      return time;
    },
    finishDay() {
      let time, day;
      day = new Date(new Date().getTime() + this.day * 86400000)
        .toJSON()
        .slice(0, 10);
      if (this.time == 1) time = "12:00 " + day;
      else if (this.time == 2) time = "15:00 " + day;
      else if (this.time == 3) time = "18:00 " + day;
      return time;
    },
  },
  methods: {
    addSpace,
    capitalizeProp,
    checkEmail,
    closeAllEmail() {
      this.isEmail = false;
      this.isEmail2 = false;
      this.isVideoCall = false;
      this.isOffer = false;
      this.reset();
    },
    reset() {
      this.isConfirm = false;
      this.isEmpty = false;
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.recEmail = "";
      this.phone = "";
      this.phoneCode = "+1";
      this.message = "";
      this.postalCode = "";
      this.service = "";
      this.time = "";
      this.day = "";
      this.offer = "";
      this.currency = "USD";
    },
    async submitEmail() {
      if (!this.isConfirm) {
        this.isEmpty = true;
        return;
      }
      if (
        !this.firstName ||
        !this.lastName ||
        !this.email ||
        !this.recEmail ||
        !this.message ||
        checkEmail(this.email) ||
        checkEmail(this.recEmail)
      ) {
        this.isEmpty = true;
        return;
      }
      try {
        this.$store.dispatch("changeLoading", true);
        await customAxios.post("create-truck-friend-email/", {
          truck: this.id,
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          recipient_email: this.recEmail,
          message: this.message,
        });
        this.$store.dispatch("changeLoading", false);
        this.closeAllEmail();
        toast.success("Success!");
      } catch (e) {
        this.$store.dispatch("changeLoading", false);
        this.$store.dispatch("setError", e);
      }
    },
    async sendEmail2() {
      if (!this.isConfirm) {
        this.isEmpty = true;
        return;
      }
      if (
        !this.firstName ||
        !this.lastName ||
        !this.email ||
        !this.message ||
        !this.phone ||
        !this.postalCode ||
        checkEmail(this.email)
      ) {
        this.isEmpty = true;
        return;
      }
      try {
        this.$store.dispatch("changeLoading", true);
        await customAxios.post("create-truck-email/", {
          truck: this.id,
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          phone: this.filteredPhone,
          postal_code: this.postalCode,
          message: this.message,
        });
        this.$store.dispatch("changeLoading", false);
        this.closeAllEmail();
        toast.success("Success!");
      } catch (e) {
        this.$store.dispatch("changeLoading", false);
        this.$store.dispatch("setError", e);
      }
    },
    async sendVideoEmail() {
      if (!this.isConfirm) {
        this.isEmpty = true;
        return;
      }
      if (
        !this.firstName ||
        !this.lastName ||
        !this.email ||
        !this.message ||
        !this.phone ||
        !this.postalCode ||
        !this.service ||
        !this.time ||
        !this.day ||
        checkEmail(this.email)
      ) {
        this.isEmpty = true;
        return;
      }
      try {
        this.$store.dispatch("changeLoading", true);
        await customAxios.post("create-truck-video-chat-email/", {
          truck: this.id,
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          phone: this.filteredPhone,
          postal_code: this.postalCode,
          message: this.message,
          video_chat_service: this.service,
          start_date: this.startDay,
          end_date: this.finishDay,
        });
        this.$store.dispatch("changeLoading", false);
        this.closeAllEmail();
        toast.success("Success!");
      } catch (e) {
        this.$store.dispatch("changeLoading", false);
        this.$store.dispatch("setError", e);
      }
    },
    async submitOffer() {
      if (!this.isConfirm) {
        this.isEmpty = true;
        return;
      }
      if (
        !this.firstName ||
        !this.lastName ||
        !this.email ||
        !this.message ||
        !this.phone ||
        !this.postalCode ||
        !this.offer ||
        checkEmail(this.email)
      ) {
        this.isEmpty = true;
        return;
      }
      try {
        this.$store.dispatch("changeLoading", true);
        await customAxios.post("create-truck-offer/", {
          truck: this.id,
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          phone: this.filteredPhone,
          postal_code: this.postalCode,
          offer_amount: this.offer,
          currency: this.currency,
          message: this.message,
        });
        this.$store.dispatch("changeLoading", false);
        this.closeAllEmail();
        toast.success("Success!");
      } catch (e) {
        this.$store.dispatch("changeLoading", false);
        this.$store.dispatch("setError", e);
      }
    },
    getDesc(val) {
      if (val) {
        let arr = val.split("\r\n");
        return arr;
      } else return "";
    },
    async getTruck() {
      try {
        const res = await customAxios.get(`trucks/${this.id}/`);
        this.t = res.data;
      } catch (e) {
        alert(e);
      }
    },
    getCountry(val) {
      this.phoneCode = val;
    },
    async convert(amount) {
      if (this.secondCurrency == "USD") {
        this.amount = amount;
        return;
      }
      try {
        const res = await axios.get(
          `https://${this.host}/latest?amount=${amount}&from=USD&to=${this.secondCurrency}`
        );
        this.amount = res.data.rates[this.secondCurrency];
      } catch (e) {
        alert(e);
      }
    },
    printPdf() {
      this.isShareOpen = false;
      setTimeout(() => window.print(), 10);
    },
    closeCalculator() {
      this.isCalculator = false;
    },
    closeOffer() {
      this.isOffer = false;
    },
    closeVideo() {
      this.isVideoCall = false;
    },
    closeEmail() {
      this.isEmail = false;
    },
    closeEmail2() {
      this.isEmail2 = false;
    },
  },
  created() {
    this.getTruck();
  },
};
</script>

<style scoped>
.w-45 {
  width: 45%;
}
.input-group {
  align-items: end;
}
form {
  padding: 0;
  margin-top: 0.8rem;
}
form .left,
form .right,
.button-wrap,
.wrap {
  flex: 1;
}
.input-wrap {
  display: flex;
  flex-direction: column;
}

input,
textarea,
select {
  margin-top: 1px;
  padding: 4px;
  font-size: 0.9rem;
}
label {
  font-size: 0.9rem;
}
::placeholder {
  font-size: 0.8rem;
}
textarea {
  height: 100%;
}
.email-form .right .input-wrap {
  height: 100%;
}
.second-form .right .input-wrap {
  height: 91%;
}
.video-form .right .input-wrap:last-child {
  height: 171.5px;
}
.wrap {
  line-height: 14px;
}
.wrap input {
  background: red !important;
  margin-right: 4px;
}
.button-wrap button {
  width: 100%;
}
.wrap input,
.wrap label {
  display: inline;
  font-size: 0.68rem;
}
button.align-self-end {
  background: #0057b7;
  color: #fff;
  padding: 4px;
  border-radius: 0.2rem;
}
.send-email button {
  font-size: 0.85rem;
}
/*  */
.box button,
.send-email button {
  padding: 2px 1rem;
  background: #0057b7;
  color: #fff;
  border-radius: 0.1rem;
}
.icons button {
  padding: 3px;
  border-radius: 0.2rem;
}
.icons button svg {
  width: 85%;
}
.box {
  padding: 16px 8px;
  background: #f2f2f2;
  color: #666;
}
label {
  user-select: none;
}
.send-email h6,
.send-email p,
p {
  font-size: 0.85rem;
}
span {
  font-size: 0.8rem;
}
.general h5 {
  color: #666;
}
.general-table h6 {
  font-size: 0.9rem;
}
.general-table .first {
  width: 40%;
  background: #0057b7;
  color: #fff;
}
.general-table .second {
  width: 60%;
  background: #ededed;
}
.first,
.second {
  margin-bottom: 2px;
}
.text-decoration-underline {
  cursor: pointer;
}

.offer textarea {
  height: 210px;
}
.calc.close-btn {
  top: 1px;
  right: 1px;
  background: rgb(189, 188, 188);
  line-height: 1.4rem;
  padding: 0 6px;
}
.calc.close-btn:hover {
  background: rgb(203, 202, 202);
}
.second-footer button {
  padding: 8px;
}
@media screen and (max-width: 578px) {
  .video-form .right .input-wrap:last-child {
    max-height: 4rem;
  }
}
</style>
